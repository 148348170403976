<style scoped>
.expand-row {
  margin-bottom: 16px;
}
</style>
<template>
  <div>

    <Row class="expand-row">
			<div>
      <div v-for="item in row.interface_configs" :key=item.name>
        <Tag
          v-if="item.is_out"
          color="geekblue"
          class="expand-value"
          style="font-size: medium;"
        >
          <p style="font-size: small;">{{ item.name }}(UPLINK)</p>
        </Tag>
      </div>
      </div>
						<div>
      <div v-for="item in row.interface_configs" :key=item.name>
        <Tag
          v-if="item.out_limit"
          color="red"
          class="expand-value"
          style="font-size: medium;"
        >
          <p style="font-size: small;">{{ item.name }}({{item.out_limit}})(OUT)</p>
        </Tag>
      </div>
      </div>

			<div>
			<div v-for="item in row.interface_configs" :key=item.name>
        <Tag
          v-if="item.in_limit"
          color="orange"
          class="expand-value"
          style="font-size: medium;"
        >
          <p style="font-size: small;">{{ item.name }}({{item.in_limit}})(IN)</p>
        </Tag>
      </div>
      </div>

    </Row>
  </div>
</template>
<script>
export default {
  props: {
    row: Object,
  },
}
</script>
